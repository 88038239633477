import React from "react";
import { Link } from "react-router-dom";

function CardAdopt({ item }) {
  return (
    <div className="adoption-shop-item">
      <div className="adoption-shop-thumb">
        <img
          style={{ minHeight: 380, objectFit: "cover" }}
          src={item.image_destacada ? item.image_destacada : item.images[0]}
          alt=""
        />
        <Link to={`/mascotas/${item.id}`} className="btn">
          ¡Adoptame!
          <img src="img/icon/w_pawprint.png" alt="" />
        </Link>
      </div>
      <div className="adoption-shop-content">
        <h4 className="title">
          <Link to="/shop-details">{item.nombre}</Link>
        </h4>
        <div className="adoption-meta">
          <ul>
            <li>
              <i className="fas fa-cog" />
              <a href="/#">{item.raza}</a>
            </li>
            <li>
              <i className="fa fa-paw" /> {item.size}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardAdopt;
