import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div style={{ display: "none" }} className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img style={{ width: "115px" }} src="img/hope.png" alt="" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>
                    Organización sin fines de lucro, promovemos la adopción de
                    mascotas y no la venta.
                  </p>
                </div>
                {/* <div className="footer-contact">
                <div className="icon"><i className="fas fa-headphones" /></div>
                <div className="content">
                  <h4 className="title"><a href="tel:0987654321">747-800-9880</a></h4>
                  <span>Call Now</span>
                </div>
              </div> */}
                <div className="footer-social" style={{ marginTop: "30px" }}>
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Mascotas</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/contacts">Perro </Link>
                    </li>
                    <li>
                      <Link to="/contacts">Gato </Link>
                    </li>
                    <li>
                      <Link to="/contacts">Grandes</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Medianos</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Pequeños</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Cachorro</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Adulto</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Menu</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/breeder">Home</Link>
                    </li>
                    <li>
                      <Link to="/adoption">Adopta</Link>
                    </li>
                    <li>
                      <Link to="/contacts">SOS</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Registrate</Link>
                    </li>
                    <li>
                      <Link to="/contacts">Inicia Sesión</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Instagram</h4>
                <div className="fw-insta">
                  <ul>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img01.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img02.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img03.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img04.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img05.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img06.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img src="img/images/footer_shape01.png" alt="" />
        </div>
        <div className="footer-shape shape-two">
          <img src="img/images/footer_shape02.png" alt="" />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>
                  De VMT con <span>❤️</span> para el mundo
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/hopee.lat/"
                      target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hope.lat/"
                      target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@hope.lat" target="_blank">
                      <i className="fab fa-tiktok" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://linkedin.com/company/hope-lat"
                      target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-md-4 d-none d-md-block">
            <div className="footer-lang">
              <div className="dropdown">
                <button className="dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="img/icon/united-states.png" alt="" /> English
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                  <Link className="dropdown-item" to="/"><img src="img/icon/russia.png" alt="" />Russia</Link>
                  <Link className="dropdown-item" to="/"><img src="img/icon//thailand.png" alt="" />Thailand</Link>
                  <Link className="dropdown-item" to="/"><img src="img/icon/india.png" alt="" />India</Link>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
