import React from "react";
import { Link } from "react-router-dom";

function Adoption() {
  return (
    <section className="adoption-area">
      <div className="container">
        <div className="row align-items-center align-items-xl-end justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="adoption-img">
              <img src="img/images/adoption_img.jpg" alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="adoption-content">
              <h2 className="title">
                Trabajando por <span>ellos 🐾 </span>
              </h2>
              <p>
                En Hope, nos preocupamos por la situación actual de los animales
                sin hogar. Es por ello que nos sumamos a la comunidad de ayuda.
                Si tu eres un{" "}
                <b>
                  rescatista independiente o perteneces a un refugio o casa
                  hogar
                </b>
                , registrate y permite que HOPE te ayude en la gestión,
                apadrinamiento y adopción de tus mascotas rescatados.
              </p>
              <a href="https://app.hope.lat" target="_blank" className="btn">
                REGISTRATE <img src="img/icon/w_pawprint.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="adoption-shape">
        <img src="img/images/adoption_shape.png" alt="" />
      </div>
    </section>
  );
}

export default Adoption;
