import React from "react";
import BreadCumb from "../components/doglists/BreadCumb";
import AdoptionShop from "../components/AdoptionShop";
import Faq from "../components/Faq";
import BreedServices from "../components/BreedServices";
import NewsLetter from "../components/NewsLetter";
import { useParams } from "react-router-dom";
import PageConstruction from "../components/PageConstruction";

function DogList() {
  return (
    <main>
      <PageConstruction />
      {/* <BreadCumb/>
	   <AdoptionShop/> */}
    </main>
  );
}

export default DogList;
