import React, { useEffect, useState } from "react";
// import { Component } from "react";
import Slider from "react-slick";
import CardAdopt from "./CardAdopt";
import CardAdoptSkeleton from "./CardAdoptSkeleton";
import { Link } from "react-router-dom";

import axios from "axios";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="PREV" />
    </button>
  );
}

function BreedServices() {
  const settings = {
    nav: true,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <PrevArrow />,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  const [mascotas, setMascotas] = useState([]);

  useEffect(() => {
    getMascotas();
  }, []);

  const getMascotas = async () => {
    const allMascotas = await axios.post(
      "https://app.hope.lat/api/mascotas/public/get"
    );
    setMascotas(allMascotas.data.mascotas);
    // console.log(allMascotas.data.mascotas[0]);
  };

  return (
    <section className="breeds-services pt-110 pb-80">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">Los más amorosos</h5>
              <h2 className="title">Adopta hoy y salva vidas</h2>
              <p>
                En el perú hay mas de 6 millones de perros sin hogar, la
                cantidad de albergues no se da abasto, ayuda adoptando y de esa
                forma salvas 2 vidas.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <Slider className="breed-services-active slick" {...settings}>
              {mascotas.length > 0
                ? mascotas.map((item) => <CardAdopt item={item} />)
                : [1, 2, 3, 4].map((item) => <CardAdoptSkeleton />)}
            </Slider>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/adopta" className="btn">
                VER TODOS <img src="img/icon/w_pawprint.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="breed-services-shape">
        <img src="img/images/breed_services_shape01.png" alt="" />
      </div>
      <div className="breed-services-shape shape-two">
        <img src="img/images/breed_services_shape02.png" alt="" />
      </div>
    </section>
  );
}

export default BreedServices;
