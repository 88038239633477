import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

function Header() {
  const handleActive = (e) => {
    console.log("Clic en el elemento LI");

    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });

    const liElement = e.target.parentNode;
    liElement.classList.add("active");
  };
  const subActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.parentNode.parentNode.classList += " active";
  };
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }

    $(".navbar-toggle").on("click", function () {
      $(".navbar-nav").addClass("mobile_menu");
    });
    $(".navbar-nav li a").on("click", function () {
      $(".navbar-collapse").removeClass("show");
    });

    $(".header-search > a").on("click", function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });

    $(".search-close").on("click", function () {
      $(".search-popup-wrap").slideUp(500);
    });
  }, []);

  return (
    <header>
      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav show">
                  <div className="logo">
                    <Link to="/">
                      <img
                        style={{ width: "115px" }}
                        src="/img/hope.png"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className="active menu-item-has-children">
                        <Link to="/" onClick={(e) => handleActive(e)}>
                          HOME
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/adopta" onClick={(e) => handleActive(e)}>
                          ADOPTA
                        </Link>
                      </li>
                      {/* <li className="menu-item-has-children">
                        <Link to="/" onClick={(e) => handleActive(e)}>
                          SOS
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={(e) => handleActive(e)}>
                          DONAR
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      {/* <li className="header-search"><a href="/#"><i className="flaticon-search" /></a></li> */}
                      <li className="header-btn">
                        <a
                          href="https://app.hope.lat"
                          target="_blank"
                          className="btn">
                          EMPEZAR
                          <img src="img/icon/w_pawprint.png" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img
                        style={{ height: 40, objectFit: "contain" }}
                        src="/img/hope.png"
                        alt=""
                        title="true"
                      />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    {/* clearfix */}
                    <ul className="clearfix">
                      <li>
                        <a
                          href="https://www.facebook.com/hopee.lat/"
                          target="_blank">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/hope.lat/"
                          target="_blank">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@hope.lat"
                          target="_blank">
                          <i className="fab fa-tiktok" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://linkedin.com/company/hope-lat"
                          target="_blank">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
      </div>

      <div
        className="search-popup-wrap"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true">
        <div className="search-close">
          <span>
            <i className="fas fa-times" />
          </span>
        </div>
        <div className="search-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">... Search Here ...</h2>
                <div className="search-form">
                  <form>
                    <input
                      type="text"
                      name="search"
                      placeholder="Type keywords here"
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
