import React from "react";
import MainSlider from "../components/MainSlider";
import Find from "../components/Find";
import Counter from "../components/Counter";
import Adoption from "../components/Adoption";
import BreedServices from "../components/BreedServices";
import Faq from "../components/Faq";
import AdoptionFaq from "../components/adoptions/AdoptionFaq";

function Home() {
  return (
    <main>
      <MainSlider />
      {/* <Find /> */}
      <div className="btnComienzaYa">
        <a href="https://app.hope.lat" target="_blank">
          <button className="btn">¡COMIENZA AHORA!</button>
        </a>
      </div>
      <Counter />
      <Adoption />
      <BreedServices />
      <AdoptionFaq />
    </main>
  );
}

export default Home;
