import React from "react";
import BreederDetBread from "../components/breederDetails/BreederDetBread";
import BreederContent from "../components/breederDetails/BreederContent";
import AdoptionFaq from "../components/adoptions/AdoptionFaq";
import AdoptionPuppies from "../components/adoptions/AdoptionPuppies";
import PageConstruction from "../components/PageConstruction";
import { useParams } from "react-router-dom";

function BreederDetailsPage() {
  let { id } = useParams();
  return (
    <main>
      <PageConstruction />
      {/* <BreederDetBread/>
		<BreederContent/> 
		<AdoptionFaq/>
		<AdoptionPuppies/> */}
    </main>
  );
}

export default BreederDetailsPage;
