import React from "react";

function PageConstruction() {
  return (
    <div style={{ maxWidth: 1000, margin: "20px auto", padding: "0 15px" }}>
      <h4 className="title" style={{ textAlign: "center", marginBottom: 20 }}>
        Estamos trabajando en esta sección. Muy pronto podrás acceder a ella
      </h4>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          style={{
            width: "60%",
            height: "100%",
            objectFit: "contain",
            display: "block",
          }}
          src="/img/builder.svg"
        />
      </div>
    </div>
  );
}

export default PageConstruction;
