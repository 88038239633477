
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import Home from './pages/Home';
import DogList from './pages/DogList';
import AdoptionsPages from './pages/AdoptionsPages'
import ContactPage from './pages/ContactPage'
import BlogPage from './pages/BlogPage'
import BlogDetailsPage from './pages/BlogDetailsPage'
import ShopPages from './pages/ShopPages'
import ShopDetailsPage from './pages/ShopDetailsPage'
import BreederPage from './pages/BreederPage'
import BreederDetailsPage from './pages/BreederDetailsPage'
import HometwoPage from './pages/HometwoPage'
// import ScrollToTopRoute from './ScrollToTopRoute'
import React from 'react'
function App() {
  
  return (
  <div className='app'>
   
   <Router>
       <Header/> 
       <Switch>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/adopta' element={<DogList />} />
          <Route exact path='/adoption' element={<AdoptionsPages />} />
          <Route exact path='/contacts' element={<ContactPage />} />
          <Route exact path='/blogs' element={<BlogPage />} />
          <Route exact path='/blog-details' element={<BlogDetailsPage />} />
          <Route exact path='/shop' element={<ShopPages />} />
          <Route exact path='/shop-details' element={<ShopDetailsPage />} />
          <Route exact path='/breeder' element={<BreederPage />} />
          <Route exact path='/breeder-details' element={<BreederDetailsPage />} />

          <Route exact path='/mascotas' element={<DogList />} /> 
          <Route exact path='/home-two' element={<HometwoPage />} />
          <Route exact path='/mascotas/:id' element={<BreederDetailsPage />} />
       </Switch>
       <Footer/>
   </Router>
 
  </div>
  );
}

export default App;
   