import React from "react";

function AdoptionFaq() {
  return (
    <section className="faq-area faq-two-bg" style={{ background: "#d5e6eb" }}>
      <div className="faq-two-img" />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-7 col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title mb-35">
                <h5 className="sub-title">FAQ</h5>
                <h2 className="title">Sobre Hope</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne">
                        ¿Qué es hope?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      Hope es una plataforma 100% <b>gratuita de por vida</b>{" "}
                      para todos los albergues y personas rescatistas
                      independientes. Nuestra meta es ayudarlos a que puedan
                      conseguir familias para las mascotas que pudieron
                      rescatar. De esta forma aportamos nuestro granito de
                      arena.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                        ¿Cómo funciona?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <b>*Refugios o Rescatistas</b> <br />
                      Podrán registrarse y posteriormente subir la información
                      de cada uno de las mascotas que rescataron, de esta forma
                      se creará una vitrina digital con toda la información
                      necesaria para que posibles familias lo puedan adoptar.
                      Podrán gestionar la adopción desde HOPE incluyendo los
                      terminos que cada uno maneje y por ultimo generar un
                      certificado de adoción emitido por Hope y el Refugio o
                      Rescatista para la nueva familia. <br />
                      <br />
                      <b>*Familias en busca de mascotas</b> <br />
                      Podrán registrarse y buscar la mascota que deseen, una vez
                      localizada, solicitarán la adopción en donde el proceso
                      empieza y el Refugio o rescatista se pondra en contacto
                      con ellos para que puedan continuar con esta adopción.{" "}
                      <br /> <br />
                      <b>*SOS</b> <br />
                      Es una función en donde cualquier persona registrada o no
                      registrada podrá acceder rápidamente, mediante el boton
                      flotante de la izquierda, o en la opción del menú.
                      <br />
                      Haciendo uso de esto podrán reportar alguna mascota que
                      vean que necesite ser rescatada, solo con la foto, un
                      numero de contacto (para que ayude a encontrarlo, quiza
                      dando mejores referencias de la ubicación, donde fue visto
                      por ultima vez, etc). Hope obtendrá tu ubicación desde
                      donde mandas la alerta SOS y lo distribuirá a los refugios
                      mas cercanos para que acudan en ayuda de dicha mascota.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                        ¿Cuánto cuesta?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      Hope nunca te cobrará algo, ni a los adoptantes ni a los
                      rescatistas y/o refugio
                      <br />
                      <br />
                      Cabe recalcar que cada refugio maneja los terminos de
                      adopción de la mascota, en lo que respecta a Hope, nunca
                      pediremos nada a los refugios, rescatistas o usuarios por
                      la adopción de alguna mascota.
                      <br />
                      <br />
                      De igual forma, tenemos un apartado de "DONACIONES", en
                      donde de manera <b>OPCIONAL</b> y <b>VOLUNTARIA</b>,
                      pueden ayudarnos a mantener la plataforma, servidores,
                      correos, marketing, etc.
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-shape">
        <img src="img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
}

export default AdoptionFaq;
