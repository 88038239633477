import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CardAdopt() {
  return (
    <div className="adoption-shop-item">
      <div className="adoption-shop-thumb">
        {/* <img src="img/product/adoption_shop_thumb01.jpg" alt="" /> */}
        <Skeleton height={380} />
      </div>
      <div className="adoption-shop-content">
        <h4 className="title">
          <Skeleton />
        </h4>
        <div className="adoption-meta">
          <ul>
            <li>
              <i className="fas fa-cog" />
              <Skeleton width={80} />
            </li>
            <li>
              <i className="far fa-calendar-alt" /> <Skeleton width={50} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardAdopt;
