import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function MainSlider() {
  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: true,

    responsive: [{ breakpoint: 767, settings: { dots: false, arrows: false } }],
  };

  return (
    <section className="slider-area slideVideo">
      <video
        className="videoPortada"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src="https://firebasestorage.googleapis.com/v0/b/hope-ae13a.appspot.com/o/Video%20Pr1-1.mp4?alt=media&token=49e11019-4a4f-457f-a766-52a7f96064b0"
        autoPlay
        loop
        muted
      />
    </section>
  );
}

export default MainSlider;
