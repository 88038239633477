import React from 'react';



function Counter() {
   

  return (
    <section className="counter-area counter-bg" style={{backgroundImage:"url('img/bg/counter_bg.jpg')"}}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8">
          <div className="counter-title text-center mb-65">
            <h6 className="sub-title">¿Por qué adoptar?</h6>
            <h2 className="title">Al adoptar salvas 2 vidas, la que te llevas contigo y la que llega </h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="counter-item">
            <h2 className="count"><span className="odometer">6</span>M</h2>
            <p>abandonados, en el Perú</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="counter-item">
            <h2 className="count"><span className="odometer">4</span>M</h2>
            <p>ABANDONADOS, SOLO EN LIMA</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="counter-item">
            <h2 className="count"><span className="odometer">80</span></h2>
            <p>Albergues, solo en lima</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <div className="counter-item">
            <h2 className="count"><span className="odometer">420</span>K</h2>
            <p>Abandonados al año, entre perro y gato</p>
          </div>
        </div>
        <div className="col-lg-12 col-12">
            <p className="parrafoCenter"> * Datos aproximados, según los registros de la organización Voz Animal </p>
        </div>
      </div>
    </div>
  </section>

  )
}

export default Counter;
